@import "@transmax/web.styles/index.scss";
@import "../../variables.scss";

.main {
  display:grid;
  grid-template-columns: 1fr 3fr;
  place-items: center center;
  border: 1px solid $fontInactive;
  border-radius: 5px;
  
}

.image img {
   width: 64px;
}

.right {
  margin: 5%;
}

.buttonContainer {
  padding-top: ($theme-spacing) * 2;
}
