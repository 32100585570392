@import '../../variables.scss';

.body {  
  //  padding-bottom: ($theme-spacing) * 2;
}

.anotherMethodLink {
  display: grid;
  place-items: center center;  
  grid-template-columns: 1fr 1fr;    
}

.buttonsContainer {
  display:grid;
  place-items: center center;  
  grid-template-columns: 1fr 1fr;  
  margin: ($theme-spacing) 0;

  button {
    margin: ($theme-spacing) 0;
  }
}