body {
  font-family: "Roboto", sans-serif;
  background: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.App {
  display: grid;
}

/* force full screen page */
html,
body,
#root,
.App {
  height: 100%;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
