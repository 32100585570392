@import '../../variables.scss';
@import "@transmax/web.styles/index.scss";

.container {
  text-align: center;
  border: 1px solid $fontInactive;
  border-radius: 5px;
  padding: 5%;  
}

.messageContainer {
  color: $logoCeladon;
  
  .username {
    display: block;
    font-size: 1.6em;
    font-weight: bold;
  }
}

.statusIcon {
  margin-top: ($theme-spacing) * 2;
  margin-bottom: ($theme-spacing) * 2;
}

.logoutButtonContainer {
  margin-top: ($theme-spacing) * 2,
}