@import '../../variables.scss';

.form {
  padding-top: $theme-spacing;
}

.field {
  width: 100%;
}

.buttonsContainer {
  margin-top: ($theme-spacing) * 2;
  width: 100%;
}

.buttonContainer {
  width: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.buttonConfirmContainer {
  padding-right: ($theme-spacing) / 2;
}

.buttonConfirm {
  width: 100%;
}

.buttonCancelContainer {
  padding-left: ($theme-spacing) / 2;
}

.buttonCancel {
  width: 100%;
}