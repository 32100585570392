@import '../../variables.scss';


.totpField {
  margin-top: ($theme-spacing) * 2;
  width: 100%;
}

.totpButton {
  margin-top: ($theme-spacing);
  margin-bottom: ($theme-spacing) * 1;
  button {
    width: 100%;
  }
}

.registerDeviceContainer {
  text-align: right;  
  margin-bottom: ($theme-spacing) * 1;
}