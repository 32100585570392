@import "@transmax/web.styles/index.scss";
// a catch all
body {
  color: $fontActive;
}
// blue hyperlinks are gross
a:link,
a:visited {
   color: $fontActive;
}
a:hover {
  color: $fontActive;
  transition: 0.5s;
}

// override MDC core theme
$mdc-theme-primary: $teal;
$mdc-theme-secondary: $fontActive;
$mdc-theme-background: white;

$mdc-theme-on-primary: $fontActive;
$mdc-theme-on-secondary: $fontActive;
$mdc-theme-on-surface: $fontActive;

// override typography
$mdc-typography-styles-headline1: (
  color: $fontActive,
  font-size: 16pt,
  padding: 0,
  margin: 0,
  line-height: normal
);

$mdc-typography-styles-caption: (
  color: $fontActive,
  font-size: 8pt,
  padding: 0,
  margin: 0
);

$mdc-typography-styles-body1: (
  color: $fontActive,
  font-size: 10pt,
  line-height: 1.5,
  padding: 0,
  margin: 0
);

$mdc-typography-styles-subtitle1: (
  color: $fontActive,
  font-size: 12pt,
  padding: 0,
  margin: 0
);

// import MDC styles - must be done AFTER override theme VARS
@import "@material/theme/mdc-theme"; // wtf
@import "@material/react-button/index.scss";
@import "@material/react-checkbox/index.scss";
@import "@material/react-text-field/index.scss";
@import "@material/react-typography/index.scss";

// Mixins to override components - must be done after imports
:not(.mdc-text-field--focused) {
  @include mdc-text-field-ink-color($fontActive);
  @include mdc-text-field-label-color($fontInactive);
  @include mdc-text-field-outline-color($fontInactive);  
  
  
}

.mdc-text-field--focused {
  @include mdc-text-field-label-color($teal);
  @include mdc-text-field-fill-color(rgba(255,255,255,.2));
}
