@import "../../variables.scss";

.main {
  display: grid;
  justify-items: center;
  align-items: center;
  align-content: stretch;
  justify-content: stretch;
  background-repeat: no-repeat;
  background-size: cover;
}
.logo {
  margin-bottom: ($theme-spacing) * 2;
}

.mainContent {
  width: 440px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.title {
  display: grid;
  align-self: start;
  place-self: stretch;
  padding-bottom: ($theme-spacing);
}

.frame {
  width: 100%;
}

.innerFrame {
  width: 100%;
}

.footer {
  margin-top: 10px;  
}